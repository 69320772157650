import { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { SideBarBackDrop } from '../SideBarBackDrop/SideBarBackDrop';
import { Sidebar } from '../Sidebar/Sidebar';
//  import { Container } from 'components/Container';
import { UserInfo } from 'components/UserInfo/UserInfo';
import clsx from 'clsx';
import icons from '../../assets/icons/sprite.svg';
import s from './Header.module.scss';
import { selectorTheme } from 'redux/Auth/authSelectors';
import { themeChangeUser } from 'redux/Auth/authOperations';

// import sidebarStyles from '../Sidebar/Sidebar.module.scss';

const getActiveThemeItem = (theme, curTheme) =>
  clsx(s.dropDownItem, s[theme], theme === curTheme && s.active);

const ThemesList = forwardRef(function ThemesList(
  { changeTheme, theme, setIsThemeListOpen, openThemesBtnRef },
  ref,
) {
  useEffect(() => {
    const handleCloseThemeList = e => {
      if (e.target !== ref.current || e.target.closest('div') !== ref.current) {
        setIsThemeListOpen(p => {
          if (
            e.target === openThemesBtnRef.current ||
            e.target.closest('button') === openThemesBtnRef.current
          ) {
            return p;
          } else {
            return p ? false : p;
          }
        });
      }
    };
    document.addEventListener('click', handleCloseThemeList);

    return () => {
      setIsThemeListOpen(false);
      document.removeEventListener('click', handleCloseThemeList);
    };
  }, []);

  return (
    <div ref={ref} className={clsx(s.dropDownContent, s[theme])}>
      <div
        className={getActiveThemeItem(theme, 'light')}
        onClick={() => changeTheme('light')}
      >
        Light
      </div>
      <div
        className={getActiveThemeItem(theme, 'dark')}
        onClick={() => changeTheme('dark')}
      >
        Dark
      </div>
      <div
        className={getActiveThemeItem(theme, 'colorful')}
        onClick={() => changeTheme('colorful')}
      >
        Violet
      </div>
    </div>
  );
});

ThemesList.propTypes = {
  theme: PropTypes.string.isRequired,
  changeTheme: PropTypes.func.isRequired,
  setIsThemeListOpen: PropTypes.func.isRequired,
  openThemesBtnRef: PropTypes.any.isRequired,
};

// =====

export const Header = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectorTheme);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isThemeListOpen, setIsThemeListOpen] = useState(false);

  const themesListRef = useRef(null);
  const openThemesBtnRef = useRef(null);

  const toggleSidebar = () => {
    setShowSidebar(prev => !prev);
  };

  const changeTheme = themeValue => {
    dispatch(themeChangeUser(themeValue));
  };

  return (
    <>
      <header className={clsx(s.header, s[theme])}>
        {/* <Container> */}
        <div className={s.pageHeader}>
          <button onClick={toggleSidebar} className={s.burgerMenu}>
            <svg className={clsx(s.burgerIcon, s[theme])}>
              <use href={`${icons}#icon-burger-menu`}></use>
            </svg>
          </button>

          <div className={s.dropDown}>
            <button
              ref={openThemesBtnRef}
              className={clsx(s.dropBtn, s[theme])}
              onClick={() => {
                setIsThemeListOpen(p => !p);
              }}
            >
              Theme
              <svg className={clsx(s.arrowIcon, s[theme])}>
                <use href={`${icons}#icon-arrow-down`}></use>
              </svg>
            </button>
            {isThemeListOpen && (
              <ThemesList
                ref={themesListRef}
                openThemesBtnRef={openThemesBtnRef}
                theme={theme}
                changeTheme={changeTheme}
                setIsThemeListOpen={setIsThemeListOpen}
              />
            )}
          </div>
          <UserInfo />
        </div>
        {/* </Container> */}
      </header>
      {showSidebar && (
        <SideBarBackDrop toggleSidebar={toggleSidebar}>
          <Sidebar />
        </SideBarBackDrop>
      )}
    </>
  );
};
