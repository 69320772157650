import { currentBoard } from 'redux/Boards/boardsSelectors';
import { useSelector } from 'react-redux';

export const useTaskCardReplacing = () => {
  const columns = useSelector(currentBoard)?.columns || {};

  const isColumnsMoreThanOne = columns.length > 1;

  return isColumnsMoreThanOne;
};
