import clsx from 'clsx';
import { createPortal } from 'react-dom';
import s from './Loader.module.scss';
import { selectorIsLoading } from 'redux/Loader/loaderSelectors';
import { selectorTheme } from 'redux/Auth/authSelectors';
import { useSelector } from 'react-redux';

const loaderRoot = document.querySelector('#loader-root');

export const Loader = () => {
  const isLoading = useSelector(selectorIsLoading);
  const theme = useSelector(selectorTheme);
  return (
    isLoading &&
    createPortal(
      <div className={s.container}>
        <div className={s.triangles}>
          <div className={clsx(s.tri, s.invert, s[theme])}></div>
          <div className={clsx(s.tri, s.invert, s[theme])}></div>
          <div className={clsx(s.tri, s[theme])}></div>
          <div className={clsx(s.tri, s.invert, s[theme])}></div>
          <div className={clsx(s.tri, s.invert, s[theme])}></div>
          <div className={clsx(s.tri, s[theme])}></div>
          <div className={clsx(s.tri, s.invert, s[theme])}></div>
          <div className={clsx(s.tri, s[theme])}></div>
          <div className={clsx(s.tri, s.invert, s[theme])}></div>
        </div>
      </div>,
      loaderRoot,
    )
  );
};
